import { IFiltered } from 'interfaces/api-response';
import {
  ICategory, IFont, IPromotionalContent, ISkill, ISponsorContent,
} from '../../interface';
import { ISessionAvatar } from './components/experts/components/edit-expert/components/sessions/interface';
import { IExpertAvatar } from './components/experts/interface';
import {
  IGroupAvatar,
} from './components/groups/interface';
import { IArticleAvatar } from './components/articles/interface';

// enum for the state of the modals
export enum ModalStates {
  close = 'CLOSE',
  webLogo = 'WEB_LOGO',
  generalInformation = 'GENERAL_INFORMATION',
  socialMedia = 'SOCIAL_MEDIA',
  category = 'CATEGORY',
  skill = 'SKILL',
  geography = 'GEOGRAPHY',
  userAllocation = 'USER_ALLOCATION',
  headerTemplate = 'HEADER_TEMPLATE',
  websiteFont = 'WEB_FONT',
  webColor = 'WEB_COLOR',
  sponsorContent = 'SPONSOR_CONTENT',
  promotionalContent = 'PROMOTIONAL_CONTENT',
  landingPage = 'LANDING_PAGE',
  landingPageSections = 'LANDING_PAGE_SECTIONS',
  groupLandingPage = 'GROUP_LANDING_PAGE',
  expertLandingPage = 'EXPERT_LANDING_PAGE',
  sessionLandingPage = 'SESSION_LANDING_PAGE',
  contentLandingPage = 'CONTENT_LANDING_PAGE',
  articleLandingPage = 'ARTICLE_LANDING_PAGE',
  sessionSelection = 'SESSION_SELECTION',
  termsConditionsPage = 'TERMS_CONDITIONS_PAGE',
  contentOnlySessionSelection = 'CONTENT_ONLY_SESSION_SELECTION',
  expertSelection = 'EXPERT_SELECTION',
  groupSelection = 'GROUP_SELECTION',
  articleSelection = 'ARTICLE_SELECTION',
  charges = 'CHARGES',
  bankDetails = 'BANK_DETAILS',
  giftCard = 'GIFT_CARDS',
  active = 'ACTIVE',
  deleteEntAcc = 'DELETE',
  landingCtaSection = 'LANDING_CTA_SECTION',
}

// form interfaces
export interface IUserAllocationForm {
  maxNoOfCoaches: number;
  maxNoOfGroups: number;
  maxNoOfEndUsers: number;
}

export interface IHeaderTemplateModalForm {
  headerTemplate: string;
}

export interface IPromotionalContentModalForm extends IPromotionalContent {
}

export interface ISponsorContentModalForm {
  sponsorContent: ISponsorContent[];
}

export interface IWebsiteColorsModalForm {
  primaryColor: string;
  secondaryColor: string;
}
export interface IWebsiteFontsModalForm {
  isFontDefault: string;
  headerFont: IFont;
  bodyFont: IFont;
}
export interface IGeneralInformationModalForm {
  name: string;
  entUrl: string;
  email: string;
  currency:string;
}
export interface IGeographyModalForm {
  country: string;
  city: string;
}
export interface ISocialMediaModalForm {
  facebookUrl: string;
  instagramUrl: string;
  linkedinUrl: string;
  youtubeUrl: string;
  twitterUrl: string;
}

export interface ICategoryModalForm {
  categories: ICategory[]
}
export interface ISkillModalForm {
  skills: ISkill[]
}

export interface IGiftCardModal {
  entId: string;
  adminId: string;
  name: string;
  code: string;
  percent_off: number;
  amount_off: number;
  currency: string;
  duration: string;
  duration_in_months: number;
}

export interface IGiftCardModalForm {
  giftCard: IGiftCardModal[];
}

export interface IWebLogoModalForm {
  entLogoUrl: string;
  webLogoUrl: string;
}

export interface ILandingPageSectionsModalForm {
  groups: boolean;
  sessions: boolean;
  featured: boolean;
  experts: boolean;
  articles: boolean;
  testimonials: boolean;
  cta?: boolean;
}
export interface ILandingPageModalForm {
  tagLine: string;
  title: string;
  body: string;
}
export interface IGroupLandingPageModalForm {
  title: string;
  body: string;
}
export interface IExpertLandingPageModalForm {
  title: string;
  body: string;
}

export interface ISessionLandingPageModalForm {
  title:string,
  body:string
}
export interface IContentLandingPageModalForm {
  title: string;
  body: string;
}
export interface IArticlesLandingPageModalForm {
  title: string;
  body: string;
}
export interface ITermsAndConditionsPageModalForm {
  link: string;
}
export interface ISessionsLandingPageModalForm {
  title: string;
  body: string;
}
export interface IChargesModalForm {
  adminFee: number;
  forExpert: number;
  forEntClient: number;
}
export interface IBankDetailsModalForm {
  accountName:string,
  accountNumber: string;
  bankName: string;
  branchName: string;
}
export interface IActive {
  active: boolean
}
export interface IEditProduct<T> {
  product: T[],
  reOrder:boolean
}

export interface ILandingCtaContent {
  title: string;
  body: string;
  buttonText: string;
  buttonLink: string;
  imageUrl: string;
  template: string;
}

// state interface
export interface EditEntAccState {
  loading:boolean,
  stateLoading:boolean,
  subStateLoading:boolean,
  id:string,
  active:boolean,
  modal:string, // this would contain the name of the modal which is concurrently open
  stripeAccountCompleted:boolean
  stripeConnectUrl:string,
  generalInformation:IGeneralInformationModalForm,
  geography:IGeographyModalForm,
  socialMedia:ISocialMediaModalForm,
  categories:ICategory[],
  skills:ISkill[],
  webLogoUrl: string,
  entLogoUrl: string,
  userAllocation: IUserAllocationForm,
  headerTemplate:IHeaderTemplateModalForm,
  promotionalContent:IPromotionalContent[],
  sponsorContent:ISponsorContent[],
  colors:IWebsiteColorsModalForm,
  websiteFonts:IWebsiteFontsModalForm,
  landingPageSections:ILandingPageSectionsModalForm,
  landingPageContent:ILandingPageModalForm,
  groupLandingPageContent:IGroupLandingPageModalForm,
  expertLandingPageContent:IExpertLandingPageModalForm,
  sessionLandingPageContent:ISessionLandingPageModalForm,
  contentLandingPageContent:IContentLandingPageModalForm,
  articlesLandingPageContent:IArticlesLandingPageModalForm,
  termsAndConditionsPage:ITermsAndConditionsPageModalForm,
  landingCtaContent?: ILandingCtaContent;
  charges: IChargesModalForm,
  bankDetails:IBankDetailsModalForm,
  experts:IExpertAvatar[],
  sessions:ISessionAvatar[],
  contentOnlySessions:ISessionAvatar[],
  groups:IGroupAvatar[],
  articles:IArticleAvatar[],
  filteredSessions:IFiltered<ISessionAvatar>,
  filteredContentOnlySessions:IFiltered<ISessionAvatar>,
  filteredExperts:IFiltered<IExpertAvatar>,
  filteredGroups:IFiltered<IGroupAvatar>,
  filteredArticles:IFiltered<IArticleAvatar>,
  giftCards: IGiftCardModal[];
  stripePlatformRegion: string;
}

export enum CtaSectionTemplate {
  TEXT_ONLY = 'text_only',
  TEXT_WITH_IMAGE = 'text_with_image',
}
